<template>
  <div class="contents detailWrap">
    <div class="title flexB">
      <h2>생육데이터 정보</h2>
    </div>
    <div class="box one">
      <div class="flexL">
        <div class="flex">
          <p>재배품목</p>
          <span>{{ growthData.crops }}</span>
        </div>
        <div class="flex">
          <p>시료</p>
          <span>{{ growthData.sample }}</span>
        </div>
        <div class="flex">
          <p>등록일</p>
          <span>{{ moment(growthData.createdAt).format("YYYY.MM.DD") }}</span>
        </div>
        <div class="flex">
          <p>초장</p>
          <span>{{ growthData.totalLT }} (cm)</span>
        </div>
        <div class="flex">
          <p>잎개수</p>
          <span>{{ growthData.leafCount }} (개)</span>
        </div>
        <div class="flex">
          <p>잎길이</p>
          <span>{{ growthData.leafLength }} (cm)</span>
        </div>
        <div class="flex">
          <p>잎너비</p>
          <span>{{ growthData.leafWidth }} (cm)</span>
        </div>
        <div class="flex">
          <p>관부직경</p>
          <span>{{ growthData.crownDmt }} (mm)</span>
        </div>
        <div class="flex">
          <p>잎자루길이</p>
          <span>{{ growthData.ptlLt }} (cm)</span>
        </div>
        <div class="flex">
          <p>화방출뢰기</p>
          <span>{{ growthData.fclBuddingDt ? moment(growthData.fclBuddingDt).format("YYYY.MM.DD") : "" }}</span>
        </div>
        <div class="flex">
          <p>개화기</p>
          <span>{{ growthData.blpr ? moment(growthData.blpr).format("YYYY.MM.DD") : "" }}</span>
        </div>
        <div class="flex">
          <p>개화수</p>
          <span>{{ growthData.planCount ? growthData.planCount : "-" }} (개)</span>
        </div>
        <div class="flex">
          <p>착과화방</p>
          <span>{{ growthData.fristFclu ? growthData.fristFclu : "-" }} (화방)</span>
        </div>
        <div class="flex">
          <p>착과수</p>
          <span>{{ growthData.fristCount ? growthData.fristCount : "-" }} (개)</span>
        </div>
        <div class="flex">
          <p>액아 발생기</p>
          <span>{{ growthData.axbdOcprd ? moment(growthData.axbdOcprd).format("YYYY.MM.DD") : "" }}</span>
        </div>
        <div class="flex">
          <p>주당 열매수</p>
          <span>{{ growthData.plantFruitCount ? growthData.plantFruitCount : "-" }} (개)</span>
        </div>
        <div class="flex">
          <p>과장</p>
          <span>{{ growthData.fruitLt ? growthData.fruitLt : "-" }} (cm)</span>
        </div>
        <div class="flex">
          <p>과중</p>
          <span>{{ growthData.flshWt ? growthData.flshWt : "-" }} (g)</span>
        </div>
        <div class="flex">
          <p>과폭</p>
          <span>{{ growthData.flshAra ? growthData.flshAra : "-" }} (cm)</span>
        </div>
        <div class="flex">
          <p>당도</p>
          <span>{{ growthData.brx ? growthData.brx : "-" }} (Brix)</span>
        </div>
        <div class="flex">
          <p>산도</p>
          <span>{{ growthData.acidity ? growthData.acidity : "-" }} (pH)</span>
        </div>
        <div class="flex">
          <p>수확량</p>
          <span>{{ growthData.hvst ? growthData.hvst : "-" }} (kg)</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchGrowthDetails } from "@/api/admin";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  name: "growthDetail",

  data() {
    return {
      moment: moment,
      growthId: "",
      growthData: [],
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "6");
  },
  mounted() {
    this.growthId = this.$route.query.id;
    this.getGrowthDetail();
  },

  methods: {
    getGrowthDetail() {
      fetchGrowthDetails(this.growthId).then((res) => {
        if (res.data.status == 200) {
          this.growthData = res.data.data;
        }
      });
    },
  },
};
</script>
